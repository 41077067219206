import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '1vh',
    paddingRight: '0.5rem',
    position: 'fixed',
  },
  searchCardStyle: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  calenderCardStyle: {
    maxHeight: '20vh',
  },
  timeCardStyle: {
    maxHeight: '15vh',
  },
}))
