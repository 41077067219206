import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { useStyle } from './HomeCSS'
import Icon from '@material-ui/core/Icon'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import { withStyles, makeStyles, CardActions, Button } from '@material-ui/core'

const stepIconStyle = makeStyles((theme) => ({
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}))

const ConnectorLine = withStyles({
  line: {
    borderLeftWidth: 2,
    minHeight: '35vh',
    marginTop: 0,
    marginBottom: -5,
    marginLeft: -5,
    borderColor: 'white',
  },
})(StepConnector)

function stepIcon(props) {
  const classes = stepIconStyle()

  return (
    <div>
      <div className={classes.circle} />
    </div>
  )
}

export default function Home() {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={5} className={classes.statContainerStyle}>
          <Grid container direction="column" spacing={3}>
            <Grid item md={12}>
              <Grid container spacing={5}>
                <Grid item md={6}>
                  <Card className={classes.userStatCardStyle}>
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        spacing={8}
                        justify="space-around"
                      >
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>
                            Riders per weekday in December, 2019
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.cardMidContentStyle}>
                            <TrendingUpIcon /> <Typography>5%</Typography>
                          </div>
                          <Typography>Since November</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        justify="flex-end"
                        className={classes.moreButtonStyle}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            endIcon={<ChevronRightIcon size="medium" />}
                          >
                            More Details
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item md={6}>
                  <Card className={classes.userStatCardStyle}>
                    <CardContent>
                      <Grid container direction="column" spacing={8}>
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography style={{ margin: '0 4vw' }}>
                            Satisfied Users in 2019
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.cardMidContentStyle}>
                            <TrendingUpIcon /> <Typography>5%</Typography>
                          </div>
                          <Typography>Since November</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        justify="flex-end"
                        className={classes.moreButtonStyle}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            endIcon={<ChevronRightIcon size="medium" />}
                          >
                            More Details
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item md={12} justify="center">
                  <Typography className={classes.ridershipTitleStyle}>
                    Ridership Parameters
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Card className={classes.ridershipCardStyle}>
                        <CardContent>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>Punctuality</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card className={classes.ridershipCardStyle}>
                        <CardContent>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>Probability Of Getting A Seat</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card className={classes.ridershipCardStyle}>
                        <CardContent>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>Punctuality</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={3}>
                      <Card className={classes.ridershipCardStyle}>
                        <CardContent>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>Punctuality</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} className={classes.statContainerStyle}>
          <Stepper
            className={classes.stepperStyle}
            orientation="vertical"
            connector={<ConnectorLine />}
          >
            <Step active>
              <StepLabel
                classes={{
                  label: classes.stepperLableStyle,
                }}
                StepIconComponent={stepIcon}
              >
                Total Buses: 200
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel
                classes={{
                  label: classes.stepperLableStyle,
                }}
                StepIconComponent={stepIcon}
              >
                Population within 400m of bus stop: 58%
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel
                classes={{
                  label: classes.stepperLableStyle,
                }}
                StepIconComponent={stepIcon}
              >
                Avg Wait Time: 18 mins
              </StepLabel>
            </Step>
          </Stepper>
        </Grid>
        <Grid item md={4} className={classes.statContainerStyle}>
          <Grid container direction="column" spacing={3}>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Card className={classes.vehicleStatCardStyle}>
                    <CardContent>
                      <Grid container direction="column" spacing={3}>
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            96%
                          </Typography>
                          <Typography>Road Crashes in Dec, 2019</Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.vehicleCardMidContentStyle}>
                            <TrendingDownIcon />
                            <Typography>5%</Typography>
                          </div>
                          <Typography>Since November</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>Graph</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={6}>
                  <Card className={classes.vehicleStatCardStyle}>
                    <CardContent>
                      <Grid container direction="column" spacing={3}>
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            13,72,800
                          </Typography>
                          <Typography> KM operated in Jan, 2020</Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.vehicleCardMidContentStyle}>
                            <TrendingDownIcon />
                            <Typography>5%</Typography>
                          </div>
                          <Typography>Since November</Typography>
                        </Grid>
                        <Grid item>
                          <Typography> Graph</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Card className={classes.vehicleStatCardStyle}>
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        spacing={8}
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            477,800
                          </Typography>
                          <Typography>
                            {' '}
                            Kgs Carbon Emmission Saved in Jan, 2020
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            477,800
                          </Typography>
                          <Typography>
                            {' '}
                            Kgs Carbon Emmission Saved in Jan, 2020
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={6}>
                  <Card className={classes.vehicleStatCardStyle}>
                    <CardContent>
                      <Grid container direction="column" spacing={4}>
                        <Grid item>
                          <Typography className={classes.cardH1}>
                            90%
                          </Typography>
                          <Typography> Reliablity in Dec, 2019</Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.vehicleCardMidContentStyle}>
                            <TrendingDownIcon />
                            <Typography>5%</Typography>
                          </div>
                          <Typography>Since November</Typography>
                        </Grid>
                        <Grid item>
                          <Typography> Graph</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
