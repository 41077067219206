import React, { useState } from 'react'
import { Grid, Paper, Typography, Card } from '@material-ui/core'
import SearchBox from 'packages/core/module/Layout/FilterSelection/SearchBox'
import { useStyles } from './FilterSelectionCSS'

const RouteLists = [
  { id: 1, name: 'Route1' },
  { id: 2, name: 'Area 1' },
  { id: 3, name: 'Trip 1' },
  { id: 4, name: 'Mall 2' },
  { id: 5, name: 'scen 2' },
  { id: 6, name: 'Route6' },
]

function FilterSelection() {
  const classes = useStyles()
  const [routeId, setRouteId] = useState(null)

  const handleCardClick = (routeId) => {
    console.log('the selected Route Id :', routeId)
    setRouteId(routeId)
  }

  return (
    <div className={classes.root}>
      alignContent="stretch"
      <Grid container spacing={1}>
        <Grid item md={12} xl={12}>
          <Card className={classes.searchCardStyle}>
            <SearchBox
              RouteLists={RouteLists}
              handleCardClick={handleCardClick}
              doubleColumn
            />
          </Card>
        </Grid>
        <Grid item md={12} xl={12}>
          <Card className={classes.searchCardStyle}>
            <SearchBox
              RouteLists={RouteLists}
              handleCardClick={handleCardClick}
            />
          </Card>
        </Grid>
        <Grid item md={12} xl={12}>
          <Card className={classes.calenderCardStyle}>
            <SearchBox
              RouteLists={RouteLists}
              handleCardClick={handleCardClick}
            />
          </Card>
        </Grid>
        <Grid item md={12} xl={12}>
          <Card className={classes.timeCardStyle}>
            <SearchBox
              RouteLists={RouteLists}
              handleCardClick={handleCardClick}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default FilterSelection
