/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @module root/AppShell/QueryPages
 * @summary This module exports the QueryPages component
 */
import React, { Fragment } from 'react'
import { withApollo } from 'react-apollo'
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import HeaderBar from 'packages/core/module/Layout/HeaderBar/'
import SideMenuBar from 'packages/core/module/Layout/SideMenuBar/'

import { SharedSnackbarProvider } from 'packages/common/shared/SharedSnackbar/SharedSnackbar.context'
import Home from 'packages/core/module/Home/Home'

/**
 * @summary The default Material UI theme to be used
 */
const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#F46B6B',
      // main: '#FF5C5C',
      // main: '#06224d',
    },
    secondary: {
      main: '#1b2029',
      // main: '#F46B6B',
      // main: '#FF5C5C',
    },
    success: {
      main: '#39D98A',
    },
    error: {
      main: '#FF5C5C',
    },
    background: {
      default: '#14171e',
    },
    // tonalOffset: 0.2,
    // type: 'dark',
  },
})

console.log('theme', theme)

export const LocationContext = React.createContext(null)

/**
 * @function
 * @param {object} props React props passed to this component
 * @summary This component fetches the pages for a user and passes them to `AppShell` component
 */
const Main = (props) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SharedSnackbarProvider>
            {/* <HeaderBar /> */}
            {/* <Home /> */}
            <SideMenuBar />
          </SharedSnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Fragment>
  )
}

export default withApollo(Main)
