/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react'
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Button,
  makeStyles
} from '@material-ui/core'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PersonIcon from '@material-ui/icons/Person'
import RouteMap from '../../Maps/Backup/RouteMap_Backup/RouteMap'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { useEffect } from 'react'
import { useState } from 'react'

const useStyles = makeStyles({
  infoCardStyle: {
    position: 'fixed',
    left: 100,
    top: 100,
    width: '22vw'
  },
  mapCardStyle: {
    width: '92vw',
    height: '85vh'
  },
  buttonStyle: {
    width: '10vw'
  },
  infoStyle: {
    marginLeft: '1vw'
  }
})

const GET_TRIP_DETAIL = gql`
  query getTrips($id: Int) {
    getTrips(id: $id) {
      id
      driver {
        id
        driverName
        contactNumber
      }
      vehicle {
        entityId
        vehicleNumber
        tag {
          loadingTime
          unloadingTime
        }
      }
      orders {
        startLocation {
          id
          code
          latitude
          longitude
        }
        endLocation {
          id
          code
          latitude
          longitude
        }
      }
      areas {
        actualCheckinTs
        actualCheckoutTs
        actualOperationStartTs
        actualOperationStopTs

        tripOrder
      }
      realTimeData {
        travelStatus
        liveTravelTime
      }
    }
  }
`

function TripDetails(props) {
  const classes = useStyles()
  const tripId = props.match.params.tripsIndex
  const [tripData, setTripData] = useState(null)
  const getTripDetail = async () => {
    const { data } = await props.client.query({
      query: GET_TRIP_DETAIL,
      variables: {
        id: parseInt(tripId, 10)
      }
    })
    if (data && data.getTrips) {
      setTripData(data.getTrips[0])
    }
  }

  const handleBackButtonClick = () => {
    props.history.goBack()
  }

  useEffect(() => {
    getTripDetail()
  }, [tripId])

  return (
    <Fragment>
      <Grid container>
        <Grid item md={12}>
          <Card raised className={classes.mapCardStyle}>
            <RouteMap />
          </Card>
          <Card raised className={classes.infoCardStyle}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={1}
              >
                <Grid item style={{ margin: '5px 0 15px 0' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonStyle}
                    startIcon={<ArrowBackIcon />}
                    onClick={handleBackButtonClick}
                  >
                    Back
                  </Button>
                </Grid>
                {tripData && (
                  <Fragment>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Start Location:
                        {tripData.orders ? (
                          <b> {tripData.orders[0].startLocation.code}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        End Location:
                        {tripData.orders ? (
                          <b> {tripData.orders[0].endLocation.code}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Driver Name:
                        {tripData.driver ? (
                          <b> {tripData.driver.driverName}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Vehicle Number
                        {tripData.vehicle ? (
                          <b> {tripData.vehicle.vehicleNumber}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Driver Number:
                        {tripData.driver ? (
                          <b> {tripData.driver.contactNumber}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        ETA:
                        {tripData.realTimeData ? (
                          <b> {tripData.realTimeData.liveTravelTime}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Gate Pass Number:
                        {tripData.gatePassNumber ? (
                          <b> {tripData.gatePassNumber}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-start"
                      className={classes.infoStyle}
                    >
                      <Grid item>
                        <LocalShippingIcon style={{ color: '#666' }} />
                      </Grid>
                      <Grid item style={{ marginLeft: '0.75vw' }}>
                        Invoice Number:
                        {tripData.invoiceNumber ? (
                          <b> {tripData.invoiceNumber}</b>
                        ) : (
                          <b> N/A</b>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withRouter(withApollo(TripDetails))
