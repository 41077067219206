/**
 * This module re-exports all pages of the app as lazy loaded components
 * @module asyncPages
 * @summary File containing all pages as lazy loaded components
 */

import React from 'react'
import Loadable from 'react-loadable'
import Loader from 'packages/common/ui/Loader'

/**
 * @summary Load Home Page
 */
export const AsyncHomeDashboard = Loadable({
  loader: () => import('packages/core/module/Home'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Financial Indicators Page
 */
export const AsyncFinancialIndicatorsDashboard = Loadable({
  loader: () => import('packages/core/module/FinancialIndicators'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Service Consumption Indicators Page
 */
export const AsyncServiceConsumptionIndicatorsDashboard = Loadable({
  loader: () => import('packages/core/module/ServiceConsumptionIndicators'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Service Ouputs Page
 */
export const AsyncServiceDeliveryDashboard = Loadable({
  loader: () => import('packages/core/module/ServiceDelivery'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Saftey Page
 */
// export const AsyncSafetyDashboard = Loadable({
//   loader: () => import('packages/core/module/Safety'),
//   loading: () => <Loader fullscreen={true} />,
// })

/**
 * @summary Tactical Planning Page
 */
export const AsyncTacticalPlanningDashboard = Loadable({
  loader: () => import('packages/core/module/TacticlePlanning'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Strategic Planning Page
 */
export const AsyncStrategicPlanningDashboard = Loadable({
  loader: () => import('packages/core/module/StrategicPlanning'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Operational Planning Page
 */
export const AsyncOperationalPlanningDashboard = Loadable({
  loader: () => import('packages/core/module/OperationalPlanning'),
  loading: () => <Loader fullscreen={true} />,
})

/**
 * @summary Questiona and Answer Page
 */
export const AsyncQuestionAndAnswer = Loadable({
  loader: () => import('packages/core/module/QuestionAndAnswer'),
  loading: () => <Loader fullscreen={true} />,
})
