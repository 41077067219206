import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MailIcon from '@material-ui/icons/Mail'
import { withStyles, Grid, FormGroup } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { fade, makeStyles } from '@material-ui/core/styles'
// import { ReactComponent as Logo } from "packages/common/resource/svg/Deloitte.svg";
import { ReactComponent as Logo } from '../../../../common/resource/svg/Zeliot-Logo.svg'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
    backgroundColor: fade(theme.palette.grey[300], 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.grey[300], 0.2),
    },
  },
  inputLabel: {
    padding: theme.spacing(0, 1, 2, 0),
  },
  logo: {
    width: '50%',
    height: '50%',
    margin: '-40px 2px ',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.grey[500], 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}))

const HeaderBar = (props) => {
  const classes = useStyles()
  return (
    <div>
      <AppBar color="inherit" className={classes.appBar}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={2}>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item container justify="center" xs={12} md={2}>
              <IconButton aria-label="show 4 new mails">
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton aria-label="show 17 new notifications">
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                // style={{ color: '#00000' }}

                // color="#aaa"
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default HeaderBar
