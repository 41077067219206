/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  TextField,
  InputBase,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './SearchBoxCSS'
import PropTypes from 'prop-types'

function SearchBox(props) {
  const classes = useStyles()
  const { RouteLists, handleCardClick, doubleColumn } = props
  console.log('doubleColumn', doubleColumn)
  const [searchInput, setSearchInput] = useState('')
  const [tempRouteId, setTempRouteId] = useState(null)
  const [isCardSelected, setIsCardSelected] = useState(false)

  let routeId = null
  const handleClick = (route) => () => {
    // console.log("card clicked", route.id);
    if (!tempRouteId && !isCardSelected) {
      setIsCardSelected(true)
    } else if (isCardSelected && tempRouteId === route.id) {
      setIsCardSelected(!isCardSelected)
    } else if (
      (isCardSelected && tempRouteId !== route.id) ||
      (!isCardSelected && tempRouteId !== route.id)
    ) {
      setIsCardSelected(true)
    }
    setTempRouteId(route.id)
  }

  const handleSearchInput = (event) => {
    // console.log("serach input", event.target.value);
    setSearchInput(event.target.value)
  }

  const filterList = RouteLists.filter((list) => {
    let inputLowerCase = searchInput.toLocaleLowerCase()
    return list.name.toLocaleLowerCase().includes(inputLowerCase)
  })

  useEffect(() => {
    // console.log("isCardSelected", isCardSelected);
    if (isCardSelected) {
      routeId = tempRouteId
    } else {
      routeId = null
    }
    handleCardClick(routeId)
    // console.log("final routeId", routeId);
  }, [isCardSelected, tempRouteId])

  let gridLines = doubleColumn ? 12 / 2 : 12

  return (
    <div>
      <Grid
        container
        direction="column"
        style={{ padding: '8px 16px' }}
        spacing={2}
      >
        <Grid item md={12} sm={12}>
          <Paper className={classes.searchInputStyle}>
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              onChange={handleSearchInput}
            />
          </Paper>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={1}>
            {filterList.map((route) => (
              <Grid item md={gridLines} sm={gridLines} key={route.name}>
                <Card
                  classes={{
                    root:
                      tempRouteId === route.id && isCardSelected
                        ? classes.cardSelectedStyle
                        : classes.cardRootStyle,
                  }}
                  onClick={handleClick(route)}
                >
                  <CardActionArea>
                    <CardContent classes={{ root: classes.cardContentStyle }}>
                      <Typography>{route.name}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

SearchBox.propTypes = {
  RouteLists: PropTypes.array,
  doubleColumn: PropTypes.bool,
}

SearchBox.defaultProps = {
  doubleColumn: false,
}

export default SearchBox
