import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { checkTokenAuthentication } from './CheckTokenAuthentication'
import { AuthProvider, AuthConsumer } from './packages/common/authentication'
import { BrowserRouter as Router } from 'react-router-dom'
import { Query, ApolloProvider } from 'react-apollo'
import getApolloClient from './packages/common/apollo'
import Loader from './packages/common/ui/Loader'
import Helmet from 'react-helmet/es/Helmet'
import DomainConfigError from './packages/common/root/DomainConfigError'
import gql from 'graphql-tag'
import Root from './packages/common/root/Index'

/**
 * @summary Query for fetching domain configuration
 */
const GET_DOMAIN_CONFIG = gql`
  query($domain: String!) {
    domainConfiguration(domain: $domain) {
      header {
        title
        shortcutIcon
      }
      page {
        background
        title
        subtitle
        logo
        navbarConfig {
          logo
          title
        }
      }
      customPage
      customPageId
    }
  }
`

checkTokenAuthentication()

/**
 * The main root component which ReactDOM mounts into a div. This component includes a
 * few top-level HOCs to configure authentication, routing, Apollo client setup and
 * fetching initial domainConfiguration
 * @summary Index Component
 */
const IndexComponent = () => {
  return (
    // Create Auth context component
    <AuthProvider>
      <AuthConsumer>
        {({ logout }) => (
          <Router>
            <ApolloProvider client={getApolloClient({ logout })}>
              <Query
                query={GET_DOMAIN_CONFIG}
                variables={{
                  domain: window.location.host
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Loader fullscreen={true} />
                  if (error) return <DomainConfigError />
                  // console.log('data', data)
                  return (
                    <Fragment>
                      <Helmet>
                        <title> {data.domainConfiguration.header.title} </title>
                        <link
                          rel="shortcut icon"
                          href={data.domainConfiguration.header.shortcutIcon}
                        />
                      </Helmet>
                      <Root
                        page={data.domainConfiguration.page}
                        customPage={data.domainConfiguration.customPage}
                        customPageId={data.domainConfiguration.customPageId}
                      />
                    </Fragment>
                  )
                }}
              </Query>
            </ApolloProvider>
          </Router>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

ReactDOM.render(<IndexComponent />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
