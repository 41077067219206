import React, { Fragment } from 'react'
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker
} from 'react-google-maps'
import RouteDirection from './RouteDirection'

function RouteMap() {
  return (
    <Fragment>
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 12.990058, lng: 77.55249 }}
      >
        {/* <RouteDirection /> */}
      </GoogleMap>
    </Fragment>
  )
}

const RouteMapComponent = withScriptjs(withGoogleMap(RouteMap))

export default () => (
  <RouteMapComponent
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDVj1RM5nAQI3U_DytvHQLLGL5_Gphokk0&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ width: '100%', height: '100%' }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
)
