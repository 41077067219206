import { makeStyles } from '@material-ui/core/styles'
import BackgroundImage from '../../../common/resource/png/background.png'
import FleetImage from '../../../common/resource/png/fleet.jpg'
export const useStyle = makeStyles((theme) => ({
  root: {
    height: '99vh',
    // backgroundColor: theme.palette.common.black,
    backgroundImage: `url(${FleetImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  statContainerStyle: {
    margin: '2rem auto',
  },
  userStatCardStyle: {
    minHeight: '55vh',
    // backgroundColor: '#b5b5b594',
    backgroundColor: '#060606b8',
    textAlign: 'center',
  },
  cardMidContentStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 6vw',
  },
  ridershipTitleStyle: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  moreButtonStyle: {
    marginTop: '13vh',
  },
  ridershipCardStyle: {
    // backgroundColor: '#b5b5b594',
    backgroundColor: '#060606b8',
    textAlign: 'center',
    minHeight: '23vh',
  },
  cardH1: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
  },
  cardH6: {
    fontSize: '0.75rem',
    fontWeight: 'light',
  },
  stepperStyle: {
    backgroundColor: '#fff0',
    padding: '0 2vw',
  },
  stepperLableStyle: {
    fontSize: '1rem',
  },
  vehicleStatCardStyle: {
    minHeight: '43vh',
    minWidth: '10vw',
    // backgroundColor: '#b5b5b594',
    backgroundColor: '#060606b8',
    textAlign: 'center',
  },
  vehicleCardMidContentStyle: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 3vw',
  },
}))
