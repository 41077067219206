import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  searchInputStyle: {
    display: 'flex',
    minWidth: '2vh',
  },
  cardRootStyle: {
    minWidth: 50,
    borderRadius: 25,
    textAlign: 'center',
    padding: '0 0.25rem',
  },
  cardSelectedStyle: {
    minWidth: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 25,
    textAlign: 'center',
    padding: '0 0.25rem',
  },
  cardContentStyle: {
    padding: '2px 6px',
  },
  cardsContainerStyle: {
    padding: '0.5rem 1rem',
  },
}))
