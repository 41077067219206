/**
 * Stores mapping between a page's key and it's name, icon, component and path
 * @module pagesConfig
 * @summary Page configurations
 */

import * as pages from './asyncPages.js'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import TripsIcon from '@material-ui/icons/SwapCalls'
import AOIIcon from '@material-ui/icons/SelectAll'
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PinDropIcon from '@material-ui/icons/PinDrop'
import EventNoteIcon from '@material-ui/icons/EventNote'
import { ReactComponent as TrendingUp } from 'packages/common/resource/svg/trendingUp.svg'
import { ReactComponent as Dashboard } from 'packages/common/resource/svg/dashboard.svg'
import { ReactComponent as OperationalPlanning } from 'packages/common/resource/svg/Operational Planning.svg'
import { ReactComponent as ResourceInputs } from 'packages/common/resource/svg/Resource Inputs.svg'
import { ReactComponent as ServiceOutputs } from 'packages/common/resource/svg/Service Outputs.svg'
import { ReactComponent as Safety } from 'packages/common/resource/svg/Safety.svg'
import { ReactComponent as ServiceConsumption } from 'packages/common/resource/svg/Service Consumption.svg'
import { ReactComponent as StrategicPlanning } from 'packages/common/resource/svg/Strategic Planning.svg'
import { ReactComponent as TacticalPlanning } from 'packages/common/resource/svg/Tactical Planning.svg'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

import HomeIcon from '@material-ui/icons/Home'

export default [
  // {
  //   name: 'Home',
  //   icon: Dashboard,
  //   path: '/home/FinancialIndicators',
  //   order: 1,
  //   // component: pages.AsyncHomeDashboard,
  //   isDisabled: true,
  // },
  {
    name: 'Financial Indicators',
    icon: ResourceInputs,
    path: '/home/FinancialIndicators/',
    order: 2,
    component: pages.AsyncFinancialIndicatorsDashboard,
    isDisabled: false,
  },
  {
    name: 'Service Consumption Indicators',
    icon: ServiceOutputs,
    path: '/home/ServiceConsumptionIndicators/',
    order: 3,
    component: pages.AsyncServiceConsumptionIndicatorsDashboard,
    isDisabled: false,
  },
  {
    name: 'Service Deliver',
    icon: ServiceConsumption,
    path: '/home/ServiceDelivery/',
    order: 4,
    component: pages.AsyncServiceDeliveryDashboard,
    isDisabled: false,
  },
  // {
  //   name: 'Safety',
  //   icon: Safety,
  //   path: '/home/Safety/',
  //   order: 5,
  //   // component: pages.AsyncSafetyDashboard,
  //   isDisabled: true,
  // },
  {
    name: 'Stratergic Planning',
    icon: StrategicPlanning,
    path: '/home/StratergicPlanning/',
    order: 6,
    component: pages.AsyncStrategicPlanningDashboard,
    isDisabled: false,
  },
  // {
  //   name: 'Tactical Planning',
  //   icon: TacticalPlanning,
  //   path: '/home/TacticalPlanning/',
  //   order: 7,
  //   component: pages.AsyncTacticalPlanningDashboard,
  //   isDisabled: false,
  // },
  // {
  //   name: 'Operational Planning',
  //   icon: OperationalPlanning,
  //   path: '/home/OperationalPlanning/',
  //   order: 8,
  //   component: pages.AsyncOperationalPlanningDashboard,
  //   isDisabled: false,
  // },
  {
    name: 'Q&A',
    icon: QuestionAnswerIcon,
    path: '/home/Q&A/',
    order: 9,
    component: pages.AsyncQuestionAndAnswer,
    isDisabled: false,
  },
]
